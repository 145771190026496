import { Box } from "@chakra-ui/react";
import {
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_CALENDAR,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_ARRIVAL,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_DEPARTURE,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_ABSENT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_PRESENT,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
  AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_VIEW_ANY,
} from "@raiden/library/constants/authorizations";
import {
  postsTypePluralMessage,
  POSTS_TYPE_LIST,
} from "@raiden/library/constants/posts";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";
import {
  IoAirplaneOutline,
  IoCalendarClearOutline,
  IoCalendarOutline,
  IoCheckmarkDoneCircleOutline,
  IoClipboardOutline,
  IoDocumentTextOutline,
  IoFlagOutline,
  IoHomeOutline,
  IoNewspaperOutline,
  IoPeopleCircleOutline,
  IoPersonCircleOutline,
  IoSettingsOutline,
  IoStarOutline,
  IoWalletOutline,
} from "react-icons/io5";
import { FormattedMessage } from "react-intl";
import uniqid from "uniqid";

/**
 * @param {object} params
 * @param {string | string[]} params.authorizations
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").ItemValidator} [params.nextValidator]
 *
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").ItemValidator}
 */
const authorizationValidator = ({ authorizations, nextValidator }) => {
  return (params) => {
    if (
      params.canPerform({
        authorizations: authorizations,
      })
    ) {
      if (nextValidator) {
        return nextValidator(params);
      }
      return true;
    }
    return false;
  };
};

/**
 * @param {object} [params]
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").ItemValidator} [params.nextValidator]
 *
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").ItemValidator}
 */
const animatorGuardValidator = (params) => {
  const nextValidator = params?.nextValidator;
  return (params) => {
    if (
      (params.user?.groups ?? []).some(
        (group) =>
          group?.alias ===
          process.env.NEXT_PUBLIC_USERS_ADMINS_GROUP_ALIASES_VALUE_ANIMATOR,
      )
    ) {
      return false;
    }

    if (nextValidator) {
      return nextValidator(params);
    }
    return true;
  };
};

/** @type {import("../containers/SignedInLayout/Menu/MenuItem").ItemDeclaration[]} */
const _MENU_ITEMS = [
  {
    title: <FormattedMessage defaultMessage="Accueil" />,
    icon: IoHomeOutline,
    href: generateAdminPath({ id: "dashboard" }),
  },
  {
    title: <FormattedMessage defaultMessage="Paramètres" />,
    icon: IoSettingsOutline,
    validator: animatorGuardValidator(),
    children: [
      {
        title: <FormattedMessage defaultMessage="Environnements" />,
        href: generateAdminPath({ id: "@environments.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_ENVIRONMENTS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Groupes d'utilisateurs" />,
        href: generateAdminPath({ id: "@usersGroups.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_GROUPS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Administrateurs" />,
        href: generateAdminPath({ id: "@usersAdmins.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_USERS_ADMINS_VIEW_ANY,
        }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Système" />,
    icon: IoDocumentTextOutline,
    validator: animatorGuardValidator(),
    children: [
      {
        title: <FormattedMessage defaultMessage="Laravel Horizon" />,
        href: generateAdminPath({ id: "@internalHorizon.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Laravel Telescope" />,
        href: generateAdminPath({ id: "@internalTelescope.view" }),
        validator: ({ isRoot }) => isRoot,
      },
      {
        title: <FormattedMessage defaultMessage="Statut" />,
        href: generateAdminPath({ id: "@healthStatus.view" }),
        validator: ({ isRoot }) => isRoot,
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Contenus" />,
    icon: IoNewspaperOutline,
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_POSTS_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
    children: [
      ...POSTS_TYPE_LIST.map((postType) => ({
        title: (
          <Box sx={{ "&::first-letter": { textTransform: "uppercase" } }}>
            <FormattedMessage
              {...postsTypePluralMessage}
              values={{ type: postType.id }}
            />
          </Box>
        ),
        href: generateAdminPath({
          id: "@posts.viewAny",
          parameters: {
            postType: postType.id,
          },
        }),
      })),
      {
        title: <FormattedMessage defaultMessage="Menus" />,
        href: generateAdminPath({ id: "@menus.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_MENUS_VIEW_ANY,
        }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Finance" />,
    icon: IoWalletOutline,
    validator: animatorGuardValidator(),
    children: [
      {
        title: <FormattedMessage defaultMessage="Factures" />,
        href: generateAdminPath({ id: "@invoices.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_INVOICES_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Comptabilité" />,
        href: generateAdminPath({ id: "@accountRecords.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_ACCOUNTS_RECORDS_VALUE_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Paiements" />,
        href: generateAdminPath({ id: "@payments.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_PAYMENTS_VIEW_ANY,
        }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Transporteurs" />,
    icon: IoAirplaneOutline,
    href: generateAdminPath({ id: "@carriers.viewAny" }),
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CARRIERS_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Pays" />,
    icon: IoFlagOutline,
    href: generateAdminPath({ id: "@countries.viewAny" }),
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_COUNTRIES_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Configuration" />,
    icon: IoSettingsOutline,
    validator: animatorGuardValidator(),
    children: [
      {
        title: <FormattedMessage defaultMessage="Périodes" />,
        href: generateAdminPath({ id: "@years.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_YEARS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Établissements" />,
        href: generateAdminPath({ id: "@places.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_PLACES_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Classes" />,
        href: generateAdminPath({ id: "@classrooms.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_CLASSROOMS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Documents" />,
        href: generateAdminPath({ id: "@documents.viewAny" }),
        validator: authorizationValidator({
          authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_DOCUMENTS_VIEW_ANY,
        }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Comptes famille" />,
    icon: IoPeopleCircleOutline,
    href: generateAdminPath({ id: "@customers.viewAny" }),
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_CUSTOMERS_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Adhérents" />,
    icon: IoPersonCircleOutline,
    href: generateAdminPath({ id: "@holders.viewAny" }),
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_HOLDERS_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Activités" />,
    icon: IoStarOutline,
    validator: animatorGuardValidator(),
    children: [
      {
        title: <FormattedMessage defaultMessage="Activités" />,
        href: generateAdminPath({ id: "@prestations.viewAny" }),
        isActive: ({ pathWithoutQueryParams }) =>
          pathWithoutQueryParams.startsWith(
            generateAdminPath({ id: "@prestations.viewAny" }),
          ) &&
          !pathWithoutQueryParams.startsWith(
            generateAdminPath({ id: "@prestations.categories.viewAny" }),
          ) &&
          !pathWithoutQueryParams.startsWith(
            generateAdminPath({ id: "@prestations.groups.viewAny" }),
          ),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Catégories" />,
        href: generateAdminPath({ id: "@prestations.categories.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_CATEGORIES_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Groupes" />,
        href: generateAdminPath({ id: "@prestations.groups.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_PRESTATIONS_GROUPS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Cotisations" />,
        href: generateAdminPath({ id: "@subscriptions.viewAny" }),
        isActive: ({ pathWithoutQueryParams }) =>
          pathWithoutQueryParams.startsWith(
            generateAdminPath({ id: "@subscriptions.viewAny" }),
          ) &&
          !pathWithoutQueryParams.startsWith(
            generateAdminPath({ id: "@subscriptions.customers.viewAny" }),
          ),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_VIEW_ANY,
        }),
      },
      {
        title: <FormattedMessage defaultMessage="Souscriptions" />,
        href: generateAdminPath({ id: "@subscriptions.customers.viewAny" }),
        validator: authorizationValidator({
          authorizations:
            AUTHORIZATIONS_AUTHORIZATION_VALUE_SUBSCRIPTIONS_CUSTOMERS_VIEW_ANY,
        }),
      },
    ],
  },
  {
    title: <FormattedMessage defaultMessage="Sessions" />,
    icon: IoCalendarOutline,
    href: generateAdminPath({ id: "@sessions.viewAny" }),
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.viewAny" }),
      ) &&
      !pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.registrations.viewAny" }),
      ) &&
      !pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.registrations.dates.viewAny" }),
      ),
    validator: authorizationValidator({
      authorizations: AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_VIEW_ANY,
      nextValidator: animatorGuardValidator(),
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Inscriptions" />,
    icon: IoClipboardOutline,
    href: generateAdminPath({ id: "@sessions.registrations.viewAny" }),
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.registrations.viewAny" }),
      ) &&
      !pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.registrations.dates.viewAny" }),
      ),
    validator: authorizationValidator({
      authorizations:
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_VIEW_ANY,
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Planning" />,
    icon: IoCalendarClearOutline,
    href: generateAdminPath({ id: "@sessions.registrations.dates.calendar" }),
    isActive: ({ pathWithoutQueryParams }) =>
      pathWithoutQueryParams.startsWith(
        generateAdminPath({ id: "@sessions.registrations.dates.viewAny" }),
      ),
    validator: authorizationValidator({
      authorizations:
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_CALENDAR,
    }),
  },
  {
    title: <FormattedMessage defaultMessage="Appel" />,
    icon: IoCheckmarkDoneCircleOutline,
    href: generateAdminPath({ id: "@badger.viewAny" }),
    validator: authorizationValidator({
      authorizations: [
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE,
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_PRESENT,
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_PRESENCE_ABSENT,
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_ARRIVAL,
        AUTHORIZATIONS_AUTHORIZATION_VALUE_SESSIONS_REGISTRATIONS_DATES_UPDATE_DEPARTURE,
      ],
    }),
  },
];

/**
 * adds uniqid & depth to each item
 * @param {import("../containers/SignedInLayout/Menu/MenuItem").ItemDeclaration[]} items
 * @param {number} [depth]
 * @returns {import("../containers/SignedInLayout/Menu/MenuItem").Item[]}
 */
function prepare(items, depth = 0) {
  return items.map((item) => ({
    ...item,
    id: uniqid(),
    depth,
    children: item.children && prepare(item.children, depth + 1),
  }));
}

export const MENU_ITEMS = prepare(_MENU_ITEMS);
